import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import ImageSlider from "../components/ImageSlider/ImageSlider"

const SecondChance = () => {
  const hasForm = true
  const images = [
    "FOMS/DEV/shareasmile-recipient-2022-1",
    "FOMS/DEV/shareasmile-recipient-2022-2",
    "FOMS/DEV/shareasmile-recipient-2022-3"
  ]

  return (
    <SharedStateProvider>
      <Layout pageTitle="secondchance" language={"en"}>
        <SEO
          lang="en"
          title="Second Chance Program at Fresno Oral Maxillofacial Surgery & Dental Implant Center"
          description="It is time for a brand new, healthy, beautiful smile."
        />

        <div className="sc-program">
          <div className="sc__hero">
            <img
              className="sc__hero-img"
              src="https://res.cloudinary.com/nuvolum/image/upload/f_auto,q_99/v1/Programs/second-chance-mobile-hero-image"
              alt="program banner"
            />
          </div>

          <div className="sc__section">
            <div className="">
              <div className="sc__about-grid--content">
                <h1 className="sc__subheading" style={{ fontSize: "24px" }}>
                  A Second Chance for a Healthy Smile{" "}
                </h1>
                <br />
                <p>
                  Fresno Oral Maxillofacial Surgery & Dental Implant Center is
                  proud to announce its 2024 Second Chance recipient. This
                  life-changing program provides one deserving recipient from
                  our region with a complete smile makeover free of charge.
                </p>
                <br />
                <p className="sc__subheading">Full-Arch Restoration</p>
                <br />
                <p>
                  <Link
                    to="/procedure/full-arch-restoration-fresno-clovis-reedley-madera-ca/"
                    title="Learn more about full-arch restoration"
                  >
                    Full-arch restoration
                  </Link>{" "}
                  is a healthy and effective way to replace many missing teeth.
                  The procedure works by securing a full prosthesis in the mouth
                  using as few as four dental implants. The dental implant posts
                  will permanently hold your new teeth in place while also
                  preventing bone loss.
                </p>
              </div>

              <p style={{ marginTop: "30px" }}>
                <b>
                  Thank you for your interest in the Second Chance program. The
                  application period is now closed. Applicants will be notified
                  once the selection process has been completed.
                </b>
              </p>

              <div className="sc__about-grid--info">
                <div className="sc__brackets sc__brackets--desktop">
                  <div>
                    <p className="sc__subheading">How To Apply</p>
                    <ul style={{ marginTop: "10px" }}>
                      <li>
                        <p>Complete the short application.</p>
                      </li>
                      <li>
                        <p>Upload photos of your smile and teeth.</p>
                      </li>
                      <li>
                        <p>Submit your application by October 31st, 2024.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="sc__section sc__slider">
            <ImageSlider images={images} adaptiveHeight useDots />
          </div> */}

          <div className="sc__section">
            <p>
              Stay tuned to this page,{" "}
              <a
                href="https://www.facebook.com/fresnooralsurgery"
                target="_blank"
                title="Follow us on Facebook"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/fresnooralsurgery/"
                target="_blank"
                title="Follow us on Instagram"
              >
                Instagram
              </a>{" "}
              for program updates!
            </p>
            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/procedure/dental-implants-fresno-ca/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/procedure/full-arch-restoration-fresno-clovis-reedley-madera-ca/"
                  title="Learn more about full-arch restoration"
                >
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SecondChance
